import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { FiUser, FiUsers } from "react-icons/fi";
import { Fade } from "react-reveal";
import ContainerDiv from "./layouts/ContainerDiv";

import TransitionGroup from "react-transition-group/TransitionGroup";
const PricingItem = (props) => {
    const { icon, text } = props;
    return (
        <div className="flex items-center mb-4">
            <span className="mr-1">{icon}</span>
            <span className="text-[14px]">{text}</span>
        </div>
    );
};

const PricingCard = (props) => {
    const {
        title,
        items, //[{icon: <Node /> , text: <Node />}]
        storage,
        price,
        price_terms, //yearly, monthly
        delay,
    } = props;
    return (
        <Fade bottom delay={delay * 200}>
            <div className="bg-background rounded-lg flex flex-col p-4 w-full items-start">
                <h5 className="text-lg mb-4 font-bold text-primaryText">
                    {title}
                </h5>
                {items?.map((item) => (
                    <PricingItem {...item} />
                ))}
                <div className="bg-white rounded-lg p-4 flex flex-col mb-8">
                    <h5 className="text-lg font-bold text-primaryText uppercase">
                        {storage}
                    </h5>
                    <p className="text-sm text-primaryText">of storage</p>
                </div>
                <div className="flex items-center mb-4">
                    <i className="text-xl font-bold text-placeholder font-italic">
                        Coming soon
                    </i>
                    {/* <h3 className="text-primaryText font-bold text-2xl">{price}</h3>{" "}
                <span className="text-placeholder">
                    &nbsp;/&nbsp;{price_terms}
                </span> */}
                </div>
            </div>
        </Fade>
    );
};

const HospitalPricingCard = (props) => {
    const {
        title,
        items, //[{icon: <Node /> , text: <Node />}]
        storage,
        price,
        price_terms, //yearly, monthly
        delay,
    } = props;

    const data = useStaticQuery(graphql`
        query {
            tier: file(relativePath: { eq: "hospital-tier.png" }) {
                childImageSharp {
                    fixed(width: 306) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <div className="rounded-lg w-full bg-gradient-secondary p-4 text-white flex flex-col items-start relative">
            <div
                className={`absolute right-4 bottom-4 opacity-40 md:opacity-100 flex items-center justify-center top-6 w-[285px]`}
            >
                <div className="w-full h-full">
                    <Img
                        fluid={data.tier.childImageSharp.fluid}
                        className="w-full "
                        alt=""
                    />
                </div>
            </div>
            <h5 className="text-lg mb-4 font-bold text-white">{title}</h5>
            {items?.map((item) => (
                <PricingItem {...item} />
            ))}
            <div className="bg-white rounded-lg p-4 flex flex-col mb-8">
                <h5 className="text-lg font-bold text-primaryText uppercase">
                    {storage}
                </h5>
                <p className="text-sm text-primaryText">of storage</p>
            </div>
            <div className="flex items-center mb-4">
                <i className="text-xl font-bold text-white font-italic">
                    Coming soon
                </i>
                {/* <h3 className="text-white font-bold text-2xl">{price}</h3>{" "}
                <span className="text-white">&nbsp;/&nbsp;{price_terms}</span> */}
            </div>
        </div>
    );
};

const Pricing = () => {
    const prices = [
        {
            title: "Extra - small hub tier",
            items: [
                {
                    icon: <FiUsers />,
                    text: (
                        <p>
                            Add up to <b> +5 members</b> to your hub
                        </p>
                    ),
                },
                {
                    icon: <FiUser />,
                    text: (
                        <p>
                            Create up to <b>+3 surgeons</b> to work on
                        </p>
                    ),
                },
            ],
            storage: `500 GB`,
            price: `$154.00`,
            price_terms: `month`,
        },
        {
            title: "Small hub tier",
            items: [
                {
                    icon: <FiUsers />,
                    text: (
                        <p>
                            Add up to <b> +10 members</b> to your hub
                        </p>
                    ),
                },
                {
                    icon: <FiUser />,
                    text: (
                        <p>
                            Create up to <b>+5 surgeons</b> to work on
                        </p>
                    ),
                },
            ],
            storage: `800 GB`,
            price: `$200.00`,
            price_terms: `month`,
        },
        {
            title: "Medium hub tier",
            items: [
                {
                    icon: <FiUsers />,
                    text: (
                        <p>
                            Add up to <b> +15 members</b> to your hub
                        </p>
                    ),
                },
                {
                    icon: <FiUser />,
                    text: (
                        <p>
                            Create up to <b>+10 surgeons</b> to work on
                        </p>
                    ),
                },
            ],
            storage: `2 TB`,
            price: `$499.00`,
            price_terms: `month`,
        },
        {
            title: "Large hub tier",
            items: [
                {
                    icon: <FiUsers />,
                    text: (
                        <p>
                            Add up to <b> +20 members</b> to your hub
                        </p>
                    ),
                },
                {
                    icon: <FiUser />,
                    text: (
                        <p>
                            Create up to <b>+20 surgeons</b> to work on
                        </p>
                    ),
                },
            ],
            storage: `5 TB`,
            price: `$1,250.00`,
            price_terms: `month`,
        },
    ];
    const hospitalPrice = {
        title: "Hospital tier",
        items: [
            {
                icon: <FiUsers />,
                text: (
                    <p>
                        Add up to <b> unlimited members</b> to your hub
                    </p>
                ),
            },
            {
                icon: <FiUser />,
                text: (
                    <p>
                        Create up to <b>unlimited surgeons</b> to work on
                    </p>
                ),
            },
        ],
        storage: `unlimited`,
        price: `$3,000.00`,
        price_terms: `month`,
    };
    return (
        <div
            className="bg-foreground w-full  py-12 overflow-hidden max-w-screen"
            id="pricing"
        >
            <ContainerDiv>
                <div className="flex flex-col w-full">
                    <Fade bottom cascade>
                        <div className="flex flex-col w-full items-center justify-center">
                            <h1 className="text-secondary font-bold text-3xl text-center mb-2">
                                Check out our pricing
                            </h1>
                            <p className="text-base text-primaryText text-center w-full md:w-1/2">
                                Try hospital hub to access the unlimited surgeon
                                creation, recruiting your members, and unlimited
                                storage for your media library
                            </p>
                        </div>
                    </Fade>
                    <TransitionGroup
                        appea={false}
                        ente={true}
                        exi={true}
                        className="w-full md:grid grid-cols-1 flex flex-col md:grid-cols-12 gap-6 py-6"
                    >
                        {prices.map((item, index) => {
                            return (
                                <div
                                    className="col-span-4"
                                    key={`item-${index}`}
                                >
                                    <PricingCard
                                        {...item}
                                        delay={index + 0.5}
                                    />
                                </div>
                            );
                        })}
                        <div className="col-span-8">
                            <Fade key={`item-last`} bottom delay={1000}>
                                <HospitalPricingCard {...hospitalPrice} />
                            </Fade>
                        </div>
                    </TransitionGroup>
                </div>
            </ContainerDiv>
        </div>
    );
};

export default Pricing;
