import * as React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import About from "../components/About";
import Features from "../components/Features";
import Hero from "../components/Hero";
import Footer from "../components/layouts/Footer";
import HomePageHeader from "../components/layouts/HomePageHeader";
import SecondHomePageHeader from "../components/layouts/SecondHomePageHeader";
import Platforms from "../components/Platform";
import Pricing from "../components/Pricing";
import favicon from "../images/corh-logo.svg";

// markup
const IndexPage = ({ data }) => {
    useEffect(() => {}, []);
    return (
        <main className="min-h-full w-full">
            <Helmet>
                <meta charSet="utf-8" />
                <title>CORH</title>
                <link
                    rel="icon"
                    type="image/png"
                    href={favicon}
                    sizes="16x16"
                />
            </Helmet>

            <div className="relative min-h-[768px] h-[calc(100vh-90px)] overflow-hidden w-full">
                <HomePageHeader />
                <Hero />
            </div>
            <SecondHomePageHeader />
            <About />
            <Features />
            <Platforms />
            <Pricing />
            <Footer />
        </main>
    );
};

export default IndexPage;
