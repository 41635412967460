import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Fade } from "react-reveal";
import Button from "./Button";
import ContainerDiv from "./layouts/ContainerDiv";
const Platforms = () => {
    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "white-corh.png" }) {
                childImageSharp {
                    fixed(height: 12, width: 43) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            tablet: file(relativePath: { eq: "tablet.png" }) {
                childImageSharp {
                    fixed(width: 470) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            mobile: file(relativePath: { eq: "mobile.png" }) {
                childImageSharp {
                    fixed(width: 430) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            web: file(relativePath: { eq: "web.png" }) {
                childImageSharp {
                    fixed(width: 450) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            googlePlay: file(relativePath: { eq: "google-play.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 36) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            appleStore: file(relativePath: { eq: "apple-store.png" }) {
                childImageSharp {
                    fixed(width: 123, height: 39) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <div
            className="bg-background py-12 overflow-hidden max-w-screen"
            id="platforms"
        >
            <ContainerDiv>
                <div className="w-full flex flex-col ">
                    <div className="flex-col items-center justify-center w-full">
                        <Fade bottom cascade delay={300}>
                            <div className="flex flex-col">
                                <h1 className="text-3xl font-bold mb-4 text-secondary text-center">
                                    Available to all platforms
                                </h1>
                                <p className="text-base text-primaryText text-center w-full md:w-1/2 mx-auto">
                                    Easily accessible in real-time via a mobile
                                    phone, tablet, iPad Download our app from
                                    Google play store and Apple store
                                </p>
                            </div>
                            <div className="flex items-center justify-center py-6">
                                <Link to="https://apps.apple.com/ae/app/corh-procedure-cards/id1558630903">
                                    <Button
                                        unstyled
                                        className="flex items-center justify-center h-11 mx-2 bg-black !rounded-lg"
                                    >
                                        <Img
                                            className={`rounded-lg w-[123px] h-[38px]`}
                                            fluid={
                                                data.appleStore.childImageSharp
                                                    .fluid
                                            }
                                            alt=""
                                        />
                                    </Button>
                                </Link>

                                <Link to="https://play.google.com/store/apps/details?id=com.globalorit.corhmobile">
                                    <Button
                                        unstyled
                                        className="flex items-center justify-center h-11 mx-2 bg-black !rounded-lg"
                                    >
                                        <Img
                                            className={`rounded-lg w-[123px] h-[38px]`}
                                            fluid={
                                                data.googlePlay.childImageSharp
                                                    .fluid
                                            }
                                            alt=""
                                        />
                                    </Button>
                                </Link>
                            </div>
                        </Fade>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 pt-6 mb-11">
                        <div className="text-center flex items-start justify-center w-full h-full px-6">
                            <Img
                                className={`w-full`}
                                fluid={data.tablet.childImageSharp.fluid}
                                alt=""
                            />
                        </div>
                        <div className="text-center flex items-start justify-center w-full h-full px-6">
                            <Img
                                className={`w-full`}
                                fluid={data.mobile.childImageSharp.fluid}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center justify-center w-full">
                        <Fade bottom cascade delay={200} big>
                            <div className="flex flex-col w-full md:w-1/2">
                                <h1 className="text-3xl font-bold mb-4 text-secondary text-center md:text-left">
                                    CORH on your personal computer
                                </h1>
                                <p className="text-base text-primaryText text-center md:text-left md:w-4/5 mx-auto md:mx-0">
                                    You can create your procedure card or
                                    surgeons using your personal computer or
                                    Laptop.
                                </p>
                                <Link
                                    to="https://hub.corh.app/login"
                                    className="w-[180px] pt-4 mx-auto md:mx-0"
                                >
                                    <Button>
                                        Login to
                                        <Img
                                            className="w-1/3 ml-2"
                                            fluid={
                                                data.logo.childImageSharp.fluid
                                            }
                                            alt=""
                                        />
                                    </Button>
                                </Link>
                            </div>
                        </Fade>
                        <Fade bottom cascade delay={400}>
                            <div className="flex flex-col w-4/5 md:w-1/2 items-center justify-center pt-11">
                                <Img
                                    className={`w-full`}
                                    fluid={data.web.childImageSharp.fluid}
                                    alt=""
                                />
                            </div>
                        </Fade>
                    </div>
                </div>
            </ContainerDiv>
        </div>
    );
};

export default Platforms;
