import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React, { useEffect, useState } from "react";
import { Menu } from "react-feather";
import Scrollspy from "react-scrollspy";
import Button from "../Button";
import ContainerDiv from "./ContainerDiv";

const ListItem = () => {};
const SecondHomePageHeader = (props) => {
    const { className, children } = props;
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "white-corh.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            logo2: file(relativePath: { eq: "white-corh.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    const scrollEvent = () => {
        if (document.getElementById("corh-logo-2")) {
            if (
                document.getElementById("corh-logo-2").getBoundingClientRect()
                    .top < 100
            ) {
                setShow(true);
            } else {
                setShow(false);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", scrollEvent);

        return () => {
            window.removeEventListener("scroll", scrollEvent);
        };
    }, []);
    const scrollToDiv = (e) => {
        setOpen(false);
        e.preventDefault();
        const element = document.getElementById(e.target.href.split("#")[1]);
        console.log("eee");
        window.scrollTo({
            top: element.offsetTop - 90,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <div
            className={`h-[92px] w-full flex duration-200 items-center justify-center sticky -top-[1px] z-50 bg-primary  ${
                className ? className : ""
            }`}
            id="corh-logo-2"
        >
            <ContainerDiv
                className={`items-center justify-center hidden md:flex`}
            >
                <Link to="/">
                    <div className="w-[106px] lg:w-[136px] ">
                        <Img
                            fluid={data.logo.childImageSharp.fluid}
                            alt=""
                            className={`w-full duration-300 ${
                                show ? "opacity-100" : "opacity-0"
                            }`}
                        />
                    </div>
                </Link>
                <div
                    className={`flex duration-200 transition-all ${
                        show
                            ? "mr-5 lg:mr-11 w-full pl-5 lg:pl-11 justify-start "
                            : "justify-center mx-auto pl-11"
                    } items-center h-full`}
                >
                    <Scrollspy
                        offset={-200}
                        items={[
                            "introduction",
                            "features",
                            "platforms",
                            "pricing",
                            "contacts",
                        ]}
                        currentClassName="!opacity-100 font-bold"
                        className="flex flex-col md:flex-row justify-center items-center h-full"
                    >
                        <li className={`opacity-50 duration-150`}>
                            <a
                                href="#introduction"
                                className="text-sm lg:text-base px-2 pl-4 text-white duration-200"
                                onClick={scrollToDiv}
                            >
                                Introduction
                            </a>
                        </li>
                        <li className={`opacity-50 duration-150`}>
                            <a
                                href="#features"
                                className="text-sm lg:text-base px-2 text-white duration-200"
                                onClick={scrollToDiv}
                            >
                                Features
                            </a>
                        </li>
                        <li className={`opacity-50 duration-150`}>
                            <a
                                href="#platforms"
                                className="text-sm lg:text-base px-2 text-white duration-200"
                                onClick={scrollToDiv}
                            >
                                Platforms
                            </a>
                        </li>

                        <li className={`opacity-50 duration-150`}>
                            <a
                                href="#pricing"
                                className="text-sm lg:text-base px-2 text-white duration-200"
                                onClick={scrollToDiv}
                            >
                                Pricing
                            </a>
                        </li>
                        <li className={`opacity-50 duration-150`}>
                            <a
                                href="#contacts"
                                className="text-sm lg:text-base px-2 text-white duration-200"
                                onClick={scrollToDiv}
                            >
                                Contacts
                            </a>{" "}
                        </li>
                    </Scrollspy>
                </div>
                <Link to="https://hub.corh.app/login" className="">
                    <Button
                        className={`${
                            show ? "opacity-100" : "opacity-0"
                        } duration-100 px-2 py-2 lg:p-4
                `}
                        type="foreground"
                    >
                        Login
                    </Button>
                </Link>
                <Link to="https://hub.corh.app/sign-up" className="ml-4 ">
                    <Button
                        className={`w-[128px] ${
                            show ? "opacity-100" : "opacity-0"
                        } duration-100 px-2 py-2 lg:p-4
                `}
                        type="secondary"
                    >
                        Get started
                    </Button>
                </Link>
            </ContainerDiv>

            <ContainerDiv
                className={`items-center justify-center flex md:hidden w-full`}
            >
                <Link to="/" className="flex items-center">
                    <div className="w-[136px] ">
                        <Img
                            fluid={data.logo2.childImageSharp.fluid}
                            alt=""
                            className={`w-full duration-300 mr-auto ${
                                show ? "opacity-100" : "opacity-0"
                            }`}
                        />
                    </div>
                </Link>
                <div
                    className={`ml-auto w-11 h-11 bg-secondary-dark !rounded-lg bg-opacity-40 text-white flex items-center justify-center cursor-pointer hover:opacity-100 ${
                        show ? "opacity-100" : "opacity-50"
                    }`}
                    onClick={() => {
                        setOpen((open) => !open);
                    }}
                >
                    <Menu />
                </div>
                <ul
                    className={`duration-200 absolute overflow-hidden top-[90px] w-full bg-primary flex items-center flex-col ${
                        open ? "h-[288px]" : "h-0"
                    }`}
                >
                    <li
                        className={`text-center w-full py-2 duration-150 border-b border-[#2b3578]`}
                    >
                        <a
                            href="#introduction"
                            className="px-3 text-white duration-200"
                            onClick={scrollToDiv}
                        >
                            Introduction
                        </a>
                    </li>
                    <li
                        className={`w-full text-center py-2 duration-150 border-b border-[#2b3578]`}
                    >
                        <a
                            href="#features"
                            className="px-3 text-white duration-200"
                            onClick={scrollToDiv}
                        >
                            Features
                        </a>
                    </li>
                    <li
                        className={`w-full text-center py-2 duration-150 !border-b border-[#2b3578]`}
                    >
                        <a
                            href="#platforms"
                            className="px-3 text-white duration-200"
                            onClick={scrollToDiv}
                        >
                            Platforms
                        </a>
                    </li>

                    <li
                        className={`w-full text-center py-2 duration-150 border-b border-[#2b3578]`}
                    >
                        <a
                            href="#pricing"
                            className="px-3 text-white duration-200"
                            onClick={scrollToDiv}
                        >
                            Pricing
                        </a>
                    </li>
                    <li
                        className={`w-full text-center py-2 duration-150 border-b border-[#2b3578]`}
                    >
                        <a
                            href="#contacts"
                            className="px-3 text-white duration-200"
                            onClick={scrollToDiv}
                        >
                            Contacts
                        </a>{" "}
                    </li>
                    <li
                        className={`w-full flex text-center py-5 justify-center items-center duration-150`}
                    >
                        <Link to="https://hub.corh.app/login" className="ml-4 ">
                            <Button
                                className={`w-[128px] ${
                                    show ? "opacity-100" : "opacity-0"
                                } duration-100
                `}
                                type="foreground"
                            >
                                Login
                            </Button>
                        </Link>
                        <Link
                            to="https://hub.corh.app/sign-up"
                            className="ml-4 "
                        >
                            <Button
                                className={`w-[128px] ${
                                    show ? "opacity-100" : "opacity-0"
                                } duration-100
                `}
                                type="secondary"
                            >
                                Get started
                            </Button>
                        </Link>
                    </li>
                </ul>
            </ContainerDiv>
        </div>
    );
};

export default SecondHomePageHeader;
