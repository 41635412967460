import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import Fade from "react-reveal/Fade";
import ContainerDiv from "./layouts/ContainerDiv";

const About = () => {
    const data = useStaticQuery(graphql`
        query {
            sampleVid: file(relativePath: { eq: "sample-vid.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <div
            className="min-h-[608px] h-[calc(100vh-200px)] w-full bg-gradient-primary is-active b"
            id="introduction"
        >
            <ContainerDiv
                className={
                    "items-center justify-center min-h-[512px] h-[calc(100vh-200px)]"
                }
            >
                <div className="w-full grid grid-cols-1 md:grid-cols-12 gap-6">
                    <Fade bottom cascade delay={300} fraction={0.3}>
                        <div className="col-span-5 flex flex-col justify-start items-center md:justify-start">
                            <h1 className="mb-6 text-white text-lg md:text-3xl font-bold">
                                CORH or also known as Central Operating Room Hub
                            </h1>
                            <p className="text-white md:text-left">
                                Will instantly assist healthcare professionals
                                working in surgical operating rooms worldwide,
                                sharing knowledge and inspiring confidence to
                                ensure every patient receives the highest
                                standard of care.
                            </p>
                        </div>
                    </Fade>
                    <div className="col-span-7">
                        <Fade bottom cascade delay={500} fraction={0.3}>
                            <div className="rounded-lg aspect-video overflow-hidden">
                                {/* sample-vid.png */}
                                <Img
                                    fluid={data.sampleVid.childImageSharp.fluid}
                                    alt=""
                                />
                            </div>
                        </Fade>
                    </div>
                </div>
            </ContainerDiv>
        </div>
    );
};

export default About;
