import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import ContainerDiv from "./layouts/ContainerDiv";

import Fade from "react-reveal/Fade";

const FeatureItemCard = ({ className, icon, title, text }) => {
    return (
        <Fade bottom wait={400}>
            <div
                className={`bg-background p-4 rounded-lg flex items-center ${
                    className ? className : ""
                }`}
            >
                <div className="w-[41px] h-[41px] min-w-[41px] min-h-[41px] flex items-center justify-center rounded-full bg-white mr-4">
                    {icon}
                </div>
                <div className="flex flex-col">
                    <h4 className="text-lg font-bold text-secondaryText mb-1">
                        {title}
                    </h4>
                    <p className="text-base text-primaryText">{text}</p>
                </div>
            </div>
        </Fade>
    );
};
const Features = () => {
    const data = useStaticQuery(graphql`
        query {
            surgeonFeature: file(relativePath: { eq: "features.png" }) {
                childImageSharp {
                    fixed(width: 430) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            procedureFeature: file(relativePath: { eq: "features-2.png" }) {
                childImageSharp {
                    fixed(width: 470) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            networkFeature: file(relativePath: { eq: "features-3.png" }) {
                childImageSharp {
                    fixed(width: 470) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            userAdd: file(relativePath: { eq: "user-add.png" }) {
                childImageSharp {
                    fixed(width: 36, height: 36) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            userSettings: file(relativePath: { eq: "user-settings.png" }) {
                childImageSharp {
                    fixed(width: 36, height: 36) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            gridSettings: file(relativePath: { eq: "grid-settings.png" }) {
                childImageSharp {
                    fixed(width: 36, height: 36) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            paper: file(relativePath: { eq: "paper.png" }) {
                childImageSharp {
                    fixed(width: 36, height: 36) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            users: file(relativePath: { eq: "users.png" }) {
                childImageSharp {
                    fixed(width: 36, height: 36) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            share: file(relativePath: { eq: "share.png" }) {
                childImageSharp {
                    fixed(width: 36, height: 36) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <div
            className="bg-foreground py-12 overflow-hidden max-w-screen"
            id="features"
        >
            <ContainerDiv>
                <div className="w-full flex flex-col">
                    <h1 className="text-3xl text-secondary mb-20 text-center !font-bold w-full">
                        We love you to experience our expanding features
                    </h1>
                    <div className="grid grid-cols-1 md:grid-cols-12 gap-6 md:gap-4 mb-12">
                        <div className="col-span-6 flex flex-col justify-center">
                            <Fade bottom cascade>
                                <div className="flex flex-col">
                                    <h2 className="text-2xl text-secondaryText mb-4 font-bold text-center md:text-left">
                                        Surgeon management
                                    </h2>
                                    <p className="text-lg text-primaryText mb-6 text-center md:text-left px-3 md:px-0">
                                        You can manage the surgeon you are
                                        working without carrying bulky folders.
                                    </p>
                                </div>

                                <FeatureItemCard
                                    className={`mb-4`}
                                    icon={
                                        <Img
                                            className={`w-[calc(100%-6px)]`}
                                            fluid={
                                                data.userAdd.childImageSharp
                                                    .fluid
                                            }
                                            alt=""
                                        />
                                    }
                                    title="Can add surgeon"
                                    text="You can always add new surgeons anytime you want"
                                />
                                <FeatureItemCard
                                    className={`mb-4`}
                                    icon={
                                        <Img
                                            className={`w-[calc(100%-6px)]`}
                                            fluid={
                                                data.userSettings
                                                    .childImageSharp.fluid
                                            }
                                            alt=""
                                        />
                                    }
                                    title="Manage surgeon"
                                    text="Manage your surgeon's details and procedures or their preferences"
                                />
                            </Fade>
                        </div>
                        <div className="col-span-6 text-center flex items-center justify-center relative">
                            <div className="w-full">
                                <Fade right cascade>
                                    <Img
                                        className={`w-3/5 md:w-4/5 mx-auto`}
                                        fluid={
                                            data.surgeonFeature.childImageSharp
                                                .fluid
                                        }
                                        alt=""
                                    />
                                </Fade>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse md:grid md:grid-cols-12 gap-4 mb-12">
                        <div className="col-span-6 text-center flex items-center justify-center relative">
                            <div className="w-full">
                                <Fade left cascade>
                                    <Img
                                        className={`w-3/5 md:w-4/5 mx-auto`}
                                        fluid={
                                            data.procedureFeature
                                                .childImageSharp.fluid
                                        }
                                        alt=""
                                    />
                                </Fade>
                            </div>
                        </div>
                        <div className="col-span-6 flex flex-col justify-center">
                            <Fade bottom cascade>
                                <div className="flex flex-col">
                                    <h2 className="text-[24px] text-secondaryText mb-4 font-bold text-center md:text-left">
                                        Procedure cards
                                    </h2>
                                    <p className="text-lg text-primaryText mb-6  text-center md:text-left px-3 md:px-0">
                                        The surgeon procedure card module is an
                                        online cloud app where users can access
                                        surgeon preference cards on any device
                                        to create, edit and update in real-time;
                                    </p>
                                </div>
                                <FeatureItemCard
                                    className={`mb-4`}
                                    icon={
                                        <Img
                                            className={`w-[calc(100%-6px)]`}
                                            fluid={
                                                data.gridSettings
                                                    .childImageSharp.fluid
                                            }
                                            alt=""
                                        />
                                    }
                                    title="Organize OR and items"
                                    text="You can create new procedure cards from scratch"
                                />
                                <FeatureItemCard
                                    className={`mb-4`}
                                    icon={
                                        <Img
                                            className={`w-[calc(100%-6px)]`}
                                            fluid={
                                                data.paper.childImageSharp.fluid
                                            }
                                            alt=""
                                        />
                                    }
                                    title="Take notes"
                                    text="You can create from the template. CORH provides a template for you to start on"
                                />
                            </Fade>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mb-12">
                        <div className="col-span-6 flex flex-col">
                            <Fade bottom cascade>
                                <div className="flex flex-col">
                                    <h2 className="text-[24px] text-secondaryText mb-4 font-bold text-center md:text-left">
                                        Collaboration
                                    </h2>
                                    <p className="text-lg text-primaryText mb-6  text-center md:text-left px-3 md:px-0">
                                        You can share your procedure, surgeons,
                                        and medical items, or your colleagues
                                        will share with you when you ask
                                    </p>
                                </div>

                                <FeatureItemCard
                                    className={`mb-4`}
                                    icon={
                                        <Img
                                            className={`w-[calc(100%-6px)]`}
                                            fluid={
                                                data.users.childImageSharp.fluid
                                            }
                                            alt=""
                                        />
                                    }
                                    title="Teamwork"
                                    text="Build a wide database of data together with your colleagues knowledge and experties in the OR"
                                />
                                <FeatureItemCard
                                    className={`mb-4`}
                                    icon={
                                        <Img
                                            className={`w-[calc(100%-6px)]`}
                                            fluid={
                                                data.share.childImageSharp.fluid
                                            }
                                            alt=""
                                        />
                                    }
                                    title="Data sharing"
                                    text="Sharing data with anyone with your own created content helps them to be more efficient in the OR"
                                />
                            </Fade>
                        </div>
                        <div className="col-span-6 text-center flex items-center justify-center relative">
                            <div className="w-full">
                                <Fade right cascade>
                                    <Img
                                        className={`w-4/5 md:w-4/5 mx-auto`}
                                        fluid={
                                            data.networkFeature.childImageSharp
                                                .fluid
                                        }
                                        alt=""
                                    />
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </ContainerDiv>
        </div>
    );
};

export default Features;
