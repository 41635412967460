import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React, { useEffect, useState } from "react";
import { Sun, Users, Watch } from "react-feather";
import Button from "./Button";
import ContainerDiv from "./layouts/ContainerDiv";

const Hero = () => {
    const data = useStaticQuery(graphql`
        query {
            hero: file(relativePath: { eq: "hero-image.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    const [show, setShow] = useState(false);

    const scrollEvent = () => {
        if (document.getElementById("corh-logo-2")) {
            if (
                document.getElementById("corh-logo-2").getBoundingClientRect()
                    .top < 100
            ) {
                setShow(true);
            } else {
                setShow(false);
            }
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", scrollEvent);

        return () => {
            window.removeEventListener("scroll", scrollEvent);
        };
    }, []);
    return (
        <div className="!min-h-[768px] h-[calc(100vh-90px)] w-full pt-[90px] ">
            <ContainerDiv
                className={`h-full flex flex-col lg:flex-row items-center justify-center`}
            >
                <div className="w-full relative pt-11 md:pt-0 min-h-[768px] h-full flex flex-col-reverse md:flex-row justify-end">
                    <div className="w-full lg:w-1/2 flex flex-col items-start md:items-start  md:justify-center md:mt-0 min-h-[768px] md:px-0 md:h-full md:py-11 h-full">
                        <h4 className="text-xl font-bold text-primaryText">
                            Anticipate
                        </h4>
                        <h1 className="text-3xl md:text-3xl font-bold text-secondaryText mb-4 md:mb-12">
                            THREE STEPS AHEAD
                        </h1>
                        <div className="flex items-center text-primaryText mb-4 md:mb-6">
                            <Sun className="w-5 mr-2" />
                            <span className="text-base md:text-lg">
                                Gain confidence and knowledge in the OR
                            </span>
                        </div>
                        <div className="flex items-center text-primaryText mb-4 md:mb-6">
                            <Watch className="w-5 mr-2" />
                            <span className="text-base md:text-lg">
                                Increase your efficiency
                            </span>
                        </div>
                        <div className="flex items-center text-primaryText mb-4 md:mb-6">
                            <Users className="w-5 mr-2" />
                            <span className="text-base md:text-lg">
                                Cross border collaboration and communication
                            </span>
                        </div>
                        <div className="flex items-center pl-6 md:pl-0">
                            <Link to="https://hub.corh.app/sign-up">
                                <Button type="secondary">Get started</Button>
                            </Link>
                        </div>
                    </div>
                    <div className="w-full min-h-[352px] h-[50vh] lg:w-3/5  relative mt-30  md:mt-0 md:h-full right-0 flex items-center justify-center">
                        <div className="absolute md:-right-[32px] xl:-right-[64px] z-10 h-[352px] md:h-[384px] lg:w-[576px] xl:h-[608px] flex items-center justify-center aspect-square">
                            <Img
                                className={`w-full  aspect-square`}
                                fixed={data.hero.childImageSharp.fluid}
                                alt=""
                            />
                        </div>
                    </div>
                    <div
                        className={`absolute hidden bottom-0 mb-[35px] w-full md:bottom-2 lg:flex flex-col p-4 z-20 md:w-1/2 md:left-[25%] items-center justify-center duration-200 ${
                            show ? "opacity-0" : "opacity-100"
                        }`}
                    >
                        <span className="mb-1 md:mb-4 animate-bounce">
                            Scroll to learn more
                        </span>
                        <div className="flex items-center justify-center rounded-2xl border-2 border-gray-800 px-2 pt-2 pb-3 z-10 animate-bounce">
                            <span className="w-[2px] h-[6px] bg-secondary"></span>
                        </div>
                    </div>
                </div>
            </ContainerDiv>
        </div>
    );
};

export default Hero;
