import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React, { useEffect, useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import Button from "../Button";
import ContainerDiv from "./ContainerDiv";

const HomePageHeader = (props) => {
    const { className, children } = props;
    const [show, setShow] = useState(false);
    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "corh.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    const scrollEvent = () => {
        if (document.getElementById("corh-logo-2")) {
            if (
                document.getElementById("corh-logo-2").getBoundingClientRect()
                    .top < 100
            ) {
                console.log("trueeee");
                setShow(true);
            } else {
                setShow(false);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", scrollEvent);
        return () => {
            window.removeEventListener("scroll", scrollEvent);
        };
    }, []);

    return (
        <div
            className={`h-[90px] min-h-[90px] w-screen flex items-center justify-center fixed z-40 bg-white bg-opacity-50 md:bg-opacity-70 backdrop-blur  ${
                className ? className : ""
            } `}
        >
            <ContainerDiv className={`items-center`}>
                <Link to="/" className="flex items-center">
                    <div className="w-[106px] lg:w-[136px] ">
                        <Img
                            fluid={data.logo.childImageSharp.fluid}
                            alt=""
                            className={`w-full duration-300 ${
                                show ? "opacity-0" : "opacity-100"
                            }`}
                        />
                    </div>
                </Link>
                <Link
                    to="https://hub.corh.app/login"
                    className="ml-auto mr-4 md:mr-0"
                >
                    <Button
                        className={`ml-auto duration-100 ${
                            show ? "opacity-0" : "opacity-100"
                        }`}
                        type="tertiary"
                    >
                        Login
                        <FiArrowRight className="ml-1 text-lg" />
                    </Button>
                </Link>
            </ContainerDiv>
        </div>
    );
};

export default HomePageHeader;
